// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-templates-blog-post-layout-js": () => import("./../../src/templates/BlogPostLayout.js" /* webpackChunkName: "component---src-templates-blog-post-layout-js" */),
  "component---src-templates-service-posts-js": () => import("./../../src/templates/servicePosts.js" /* webpackChunkName: "component---src-templates-service-posts-js" */),
  "component---src-templates-service-template-js": () => import("./../../src/templates/serviceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */)
}

